@import "./../../main/style.scss";


body._web-development { 
    &::-webkit-scrollbar-thumb {
        background: $main !important;
    }

    .services-page {
        .gradient-btn { background: $main; }

        &__popup-overlay {
            &::-webkit-scrollbar-thumb { background: $main !important; }
            .services-page__popup {
                &::before { background: $main; }
            }
        }

        &__main {
            .services-page__title {
                &::before { background: $main; }
            }
        }
    }
}


body._design {
    &::-webkit-scrollbar-thumb {
        background: $design-page !important;
    }

    .services-page {
        .gradient-btn { background: $design-page; }

        &__popup-overlay {
            &::-webkit-scrollbar-thumb { background: $design-page !important; }

            .services-page__popup {
                &::before { background: $design-page; }
            }
        }

        &__main {
            .services-page__title {
                &::before { background: $design-page; }
            }
        }
    }
}


body._poster-billboards {
    &::-webkit-scrollbar-thumb {
        background: $poster-page !important;
    }

    .services-page {
        .gradient-btn { background: $poster-page; }

        &__popup-overlay {
            &::-webkit-scrollbar-thumb { background: $poster-page !important; }

            .services-page__popup {
                &::before { background: $poster-page; }
            }
        }

        &__main {
            .services-page__title {
                &::before { background: $poster-page; }

            }
        }
    }
}


body._mobile-design {
    &::-webkit-scrollbar-thumb {
        background: $mobile-page !important;
    }

    .services-page {
        .gradient-btn { background: $mobile-page; }

        &__popup-overlay {
            &::-webkit-scrollbar-thumb { background: $mobile-page !important; }

            .services-page__popup {
                &::before { background: $mobile-page; }
            }
        }

        &__main {
            .services-page__title {
                &::before { background: $mobile-page; }
            }
        }
    }
}


body._business-card-leaflets {
    &::-webkit-scrollbar-thumb {
        background: $card-page !important;
    }

    .services-page {
        .gradient-btn { background: $card-page; }

        &__popup-overlay {
            &::-webkit-scrollbar-thumb { background: $card-page !important; }

            .services-page__popup {
                &::before { background: $card-page; }
            }
        }

        &__main {
            .services-page__title {
                &::before { background: $card-page; }
            }
        }
    }
}


.services-page {
    display: flex;
 

    &__popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        z-index: -1;
        opacity: 0;

        overflow-y: auto;
        overflow-x: hidden;
        @include display-center(center, center);
        display: flex;
        transition: .4s;
        &::-webkit-scrollbar { width: 5px; }


        &._active {
            z-index: 10;
            opacity: 1;
            transition: 1s;    

            .services-page__popup {
                transform: translateY(0px);
                // opacity: 1;
                transition: .4s;
            }
        }

        .services-page__popup {
            min-width: 700px;
            padding: 45px 50px;
            background-color: $black;
            box-shadow: 0 0 2px rgba($white, 0.6);
            overflow-y: auto;
            overflow-x: hidden;
            transform: translateY(-150px);
            transition: .4s;
            // opacity: 0;

            &::before {
                position: absolute;
                content: "";
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 270px;
                height: 270px;
                border-radius: 100%;
                filter: blur(250px);
                z-index: -1;
            }

            &-header {
                margin-bottom: 40px;
                @include display-center(space-between, center);
                @include font($white, 24px, 600, normal, 0);

                svg {
                    cursor: pointer;
                }
            }

            &-form {
                display: flex;
                flex-direction: column;
                row-gap: 30px;
               .services-page__popup__input-box {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    ::-webkit-scrollbar {
                        width: 5px;
                        border-radius: 5px;
                    }
                    ::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    ::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                    }
    
                    
                    span {
                        margin-bottom: 5px;
                        @include font($white, 16px, 500, normal, 0);

                        & .error {
                            @include font($error-color, 14px, 400, normal, 0);
                        }
                    }

                    .services-page__popup-input {
                        padding: 0 10px;
                        width: 100%;
                        height: 50px;
                        outline: none;
                        border: none;
                        background-color: $ligth-dark;
                        @include font($white, 16px, 500, normal, 0);
                        border: 1px solid rgba($white, 0.1);

                        -webkit-box-shadow: inset 0 0 0 50px $ligth-dark;
                        -webkit-text-fill-color: $white;
                    }

                    .services-page__popup__textarea-wrapper {
                        width: 100%;
                        background-color: $ligth-dark;
                        border: 1px solid rgba($white, 0.1);


                        .services-page__popup-textarea {
                            padding: 0px;
                            padding-right: 5px;
                            margin: 15px 10px;
                            width: calc(100% - 20px);
                            height: 100px;
                            resize: none;
                            outline: none;
                            overflow-y: auto;
                            border: none;
                        }
                    }
                }


                .gradient-btn {
                    height: 50px;
                    margin-top: 20px;
                }
            }

        }
    }

    .popup-form {
        left: 50%;
        transform: translateX(-50%);
        z-index: 11;
    }

    .header__menu-wrapper {
        display: none;
    }
    
    &__menu {
        position: fixed;
        @include display-center(space-between, flex-start);
        flex-direction: column;

        padding: 40px;
        height: 100vh;
        width: 22%;
        background-color: $secondary-dark;

        &-logo {
            opacity: 1;
            transition: .4s;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
                transition: .4s;
            }
        }


        .services-menu__wrapper {
            height: 100%;
            width: 100%;
            @include display-center(space-between, flex-start);
            flex-direction: column;
        }

        &-phantom {
            width: 22%;
        }

        &-logo svg {
            cursor: pointer;
            width: 160px;
            height: 34px;
            transition: .3s;

            &:hover {
                transition: .3s;
                opacity: 0.6;
            }
        }

        .header__wrapper {
            display: none;
        }

        &-nav {
            .services-page__menu-list {
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                .services-page__menu-item {
                    @include font($white, 18px, 600, normal, 0);
                    cursor: pointer;
                    transition: .4s;

                    &:hover {
                        color: $grey;
                        transition: .4s;
                    }
                }
            }
        }

        .header__menu {
            display: none;
        }
    }

    &__main {
        padding: 30px 50px 20px 50px;
        width: 78%;
        background-position: top;

        .services-page__user-location {
            &, & a {
                display: flex;
                align-items: center;
                column-gap: 5px;
                
                @include font($white, 16px, 500, normal, 0);
                cursor: pointer;
                transition: .4s;
            }

            &:hover {
                opacity: 0.8;
                transition: .4s;
            }
        }

        .services-page__title {
            position: relative;
            margin-top: 90px;
            margin-bottom: 25px;
            @include font($white, 48px, 700, normal, 0);
            z-index: 1;
            white-space: pre-line;

            &::before {
                position: absolute;
                left: 0;
                top: -45px;
                content: "";
                width: 200px;
                height: 80px;
                z-index: -1;
            }
        }

        .services-page__subtitle {
            margin-bottom: 50px;
            @include font($white, 20px, 400, normal, 0);
        }

        .services-page__services-container {
            margin-bottom: 140px;
            display: flex;
            flex-wrap: wrap;
            gap: 50px 6%;

            .services-page__service {
                cursor: pointer;
                transition: .4s;
                transform: translateY(0px);

                &:hover {
                    transition: .4s;
                    transform: translateY(-10px);

                    .services-page__service-title  {
                        color: $grey;
                        transition: .4s;
                    }
                }

                &-img {
                    margin-bottom: 15px;
                    width: 300px;
                    height: 180px;

                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }

                &-title {
                    @include font($white, 20px, 400, normal, 0);
                    transition: .4s;
                }

            }
        }

        .services-page__description-box {
            margin-left: 70px;
            padding-bottom: 140px;
            .services-page__description-title {
                margin-left: -70px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                column-gap: 30px;

                span {
                    display: block;
                    width: 40px;
                    min-height: 2px;
                    background: $main;

                    &:nth-child(3) {
                        display: none;
                    }

                    .error {
                        @include font($error-color, 14px, 400, normal, 0);
                    }
                }

                p {
                    @include font($white, 20px, 500, normal, 0);
                    text-transform: uppercase;
                }
            }

            .services-page__description-tags__container {
                margin-bottom: 35px;
                display: flex;
                flex-wrap: wrap;
                gap: 15px 30px;

                .services-page__description-tag {
                    padding: 8px 40px;
                    @include font($white, 15px, 400, normal, 0);
                    background: rgba(161, 161, 161, 0.20);
                }
            }

            .services-page__description {
                margin-bottom: 35px;
                @include font($white, 16px, 400, normal, 0);
            }
        }

        .services-page__footer {
            &-text {
                @include font(rgba(255, 255, 255, 0.6), 14px, 500, normal, 0);
                text-align: center;
            }
        }   
    }
}


@media screen and (max-width: 1200px) {
    .services-page {
        &__menu {
            padding: 30px;
            width: 25%;

            &-phantom {
                width: 25%;
            }
        }

        &__main {
            padding: 40px;
            width: 75%;

            .services-page__subtitle {
                font-size: 18px;
            }

            .services-page__services-container {
                .services-page__service {
                    &-title {
                        font-size: 18px;
                    }
                }
            }

            .services-page__description-box {
                margin-left: 0px;
                
                .services-page__description-title {
                    margin-left: 0px;
                    margin-bottom: 15px;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
    
                    p {
                        font-size: 18px;
                    }
                }
    
                .services-page__description-tags__container {
                    margin-bottom: 30px;
                }
    
                .services-page__description {
                    margin-bottom: 20px;
                }
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    .services-page {
        &__menu {
            padding: 30px;
            width: 25%;

            &-logo svg {
                width: 150px;
                height: 31px;
            }

            &-phantom {
                width: 25%;
            }

            &-nav {
                .services-page__menu-list {
                    row-gap: 15px;
                    .services-page__menu-item {
                        font-size: 15px;
                    }
                }
            }
        }

        &__main {
            .services-page__services-container {
                justify-content: space-between;
                gap: 50px 30px;

                .services-page__service {
                    &-img {
                        width: 240px;
                        height: 144px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 800px) {
    $main-padding: 30px;

    .services-page {
        display: block;

        &._menu-open {
            .header__menu-overlay {
                display: block;
                position: fixed;
                width: 100%;
                height: 100vh;
                z-index: 9;
                opacity: 1;
                animation: burgerOverlayAnimation 1s .8s forwards;
                @keyframes burgerOverlayAnimation {
                    0% { top: -100vh; }
                    100% { top: 0px; }
                }
            }


            .header__menu-wrapper {
                padding: 0 $main-padding;
                margin: 0;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                width: 100vw;
                height: 100vh;
                position: absolute;
                top: 0%;
                left: 50%;
                transform: translate(-50%, -0);
                z-index: 12;
                animation: showElement 2s 1s forwards;


                .header__menu-header--phantom {
                    height: 35px;
                    margin-top: 25px;
                }

                .header__menu-row {
                    @include display-center(center, center);

                    .header__menu-nav {
                        .header__menu-list {
                            display: flex;
                            flex-direction: column;
                            row-gap: 20px;

                            .header__menu-item {
                                display: flex;
                                align-items: center;
                                transition: .3s;
                                user-select: none;
                                

                                &:hover {
                                    transform: translateX(-20px);
                                    transition: .3s;

                                    .header__menu-item__rhombus {
                                        transform: rotate(-45deg) scale(1);
                                        transition: .3s;
                                    }

                                    p {
                                        &::before {
                                            left: -90px;
                                            width: 94.3px;   
                                            transition: .3s;
                                        }
                                    }
                                }

                                &__order {
                                    width: 17px;
                                    @include font($white, 12px, 500, normal, 0);
                                    margin-right: 5px;
                                }

                                &__rhombus {
                                    margin-right: 90px;
                                    width: 6px;
                                    height: 6px;
                                    background-color: $white;
                                    transform: rotate(-45deg) scale(0);
                                    transition: .3s;
                                }

                                p {
                                    max-width: 200px;
                                    position: relative;
                                    cursor: pointer;
                                    @include font($white, 20px, 600, normal, 0);

                                    &::before {
                                        content: "";
                                        position: absolute;
                                        left: 0px;
                                        width: 0px;   
                                        min-height: 2px;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        background-color: $white;
                                        transition: .3s;
                                    }
                                }
                            }
                        }
                    }
                }


                .home__footer {
                    padding: 0;
                }
            }

            .services-page {
                position: relative;
            }

            .home__footer {
                .localisation-field {
                    display: flex;
                }
            }
        }

        &._menu-close {
            .header__menu-overlay {
                animation: burgerHideOverlayAnimation 1.5s forwards;
                opacity: 1;
                z-index: 12;
                @keyframes burgerHideOverlayAnimation {
                    0% { top: 0; };
                    100% { top: -100vh; };
                }
            }
        }


        &__popup-overlay {
            .services-page__popup {
                margin: 0 $main-padding;
                min-width: auto;
                width: 100%;
            }
        }


        .header__menu-overlay {
            top: -100vh;
            display: block;
            position: fixed;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(5px);
            opacity: 0;
            z-index: -1;

            &._quickly-close-overlay {
                display: none;
            }
        }

        .header__menu-wrapper {
            opacity: 0;
            transition: .5s;
        }


        &__menu {
            padding: 25px 30px;
            position: fixed;
            top: 0;
            width: 100%;
            height: auto;
            background-color: transparent;
            // @include display-center(space-between, none);
            // flex-direction: row;
            z-index: 9;
            transition: 0.4s;

            .services-menu__wrapper {
                width: 100%;

                flex-direction: row;
            }

            &._menu-show {
                top: 0px;
                transition: 0.4s;
                background-color: rgba(0, 0, 0, 0.8);
                backdrop-filter: blur(5px);
            }

            &._menu-hide {
                top: -100px;
                transition: 0.4s;
                background-color: transparent;
                backdrop-filter: blur(0px);
            }

            &._hide-bg {
                background-color: transparent;
                backdrop-filter: blur(0px);
            }

            .services-page__menu-nav {
                display: none;
            }
            
            &-logo--link {
                z-index: 13;
            }

            &-phantom {
                height: 100px;
            }

            .localisation-field {
                display: none;
            }

            .header__menu {
                display: flex;
            }
        }

        &__main {
            padding: 30px;
            width: 100%;
            height: auto;

            .services-page__user-location {
                margin-top: 40px;
            }

            .services-page__title {
                margin-top: 50px;
                margin-bottom: 25px;
                font-size: 40px;

                &::before {
                    top: -30px;
                    height: 60px;
                }
            }


            .services-page__description-box {
                .services-page__description-tags__container {
                    gap: 15px 20px;
                    .services-page__description-tag {
                        font-size: 14px;
                        padding: 8px 30px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 600px) {
    $main-padding: 30px;


    .services-page {
        &._menu-open {
            .header__menu-wrapper {
                padding: 0 $main-padding;

                .header__menu-row {
                    flex-direction: column;
                    justify-content: center;
                    column-gap: 0;

                    .header__menu-img {
                        display: none
                    } 

                    .header__menu-nav {
                        .header__menu-list {
                            .header__menu-item {
                                &:hover {
                                    transform: translateX(-15px);

                                    p {
                                        &::before {
                                            left: -70px;
                                            width: 74.3px;   
                                        }
                                    }
                                }

                                &__rhombus {
                                    margin-right: 70px;
                                }

                                p {
                                    &::before {
                                        min-height: 1px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .header__menu {
            .rolling__block {
                display: none;
            } 
        }

        &__popup-overlay {
            .services-page__popup {
                padding: 25px;

                &-header {
                    margin-bottom: 30px;
                }

                .gradient-btn {
                    margin-top: 10px;
                }
            }
        }

        &__main {
            .services-page__user-location {
                &, & a {
                    font-size: 14px;

                    svg:nth-child(1) {
                        width: 18px;
                        height: 18px;
                    }
                }

                & a {
                    white-space: nowrap;
                }
            }

            .services-page__services-container {
                .services-page__service {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                    &-img {
                        width: 260px;
                        height: 156px;
                    }
                }
            }

            .services-page__description-box {
                display: flex;
                flex-direction: column;
                align-items: center;

                .services-page__description-title {
                    justify-content: center;
                    column-gap: 10px;

                    span {
                        width: 30px;

                        &:nth-child(3) {
                            display: block;
                        }
                    }

                    p { text-align: center; }
                }

                .services-page__description-tags__container {
                    justify-content: center;
                }
    
                .services-page__description {
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    $main-padding: 30px;


    .services-page {
        &__popup-overlay {
            .services-page__popup {
                padding: 45px $main-padding;
                margin: 0;
                width: 100%;

                &-header {
                    margin-bottom: 30px;
                }

                .gradient-btn {
                    margin-top: 10px;
                }
            }
        }
    }
}


@media screen and (max-height: 800px) {
    .services-page {
       &__popup-overlay {
            .services-page__popup {
                padding-top: 30px;
                padding-bottom: 30px;
                &-header {
                    margin-bottom: 30px;
                }

                .services-page__popup-form {
                    row-gap: 20px;
                    .services-page__popup__input-box {
                        .services-page__popup-input {
                            height: 46px;
                        }

                        .services-page__popup__textarea-wrapper {
                            .services-page__popup-textarea {
                                height: 80px;
                            }
                        }
                    }
                }
            }
       } 
    }
}


@media screen and (max-height: 620px) {
    .services-page {
        &__popup-overlay {
            display: none;
            justify-content: center;
            align-items: flex-start;

            &._active {
                display: flex;
            }

            .services-page__popup {
                top: 0;
            }
        }
    }
}