@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

:focus, :active {
  outline: none; }

a:focus, a:active {
  outline: none; }

input, button, textarea {
  font-family: inherit; }

button {
  cursor: pointer; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

a, a:visited {
  text-decoration: none; }

a:hover {
  text-decoration: none; }

ul li {
  list-style: none; }

body {
  background-color: #000;
  overflow-x: hidden; }
  body._body-hidden {
    overflow: hidden; }

::selection {
  background: rgba(255, 255, 255, 0.2); }

::-webkit-scrollbar {
  width: 10px; }

::-webkit-scrollbar-track {
  background: #000; }

::-webkit-scrollbar-thumb {
  background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%); }

.App {
  overflow-x: hidden; }

.rolling__block {
  position: relative;
  height: 23px;
  overflow: hidden;
  transition: .5s; }
  .rolling__block:hover {
    transition: .5s; }
    .rolling__block:hover .rolling__block--before {
      transform: translateY(-23px); }
    .rolling__block:hover .rolling__block--after {
      transform: translateY(-23px); }
  .rolling__block .rolling__block--before, .rolling__block .rolling__block--after {
    transition: .5s; }
  .rolling__block .rolling__block--after {
    position: absolute; }

.social__media-wrapper {
  display: flex;
  column-gap: 20px; }

@media screen and (max-width: 600px) {
  .social__media-wrapper {
    column-gap: 15px; }

  .text-animation {
    display: none; } }
.App {
  overflow-x: hidden; }

.subTitle__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  padding-left: 120px;
  margin-bottom: 40px; }
  .subTitle__container-line {
    width: 80px;
    height: 2px;
    background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%); }
    .subTitle__container-line--second {
      display: none; }
  .subTitle__container-description {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: uppercase; }

@media screen and (max-width: 1200px) {
  .subTitle__container {
    padding-left: 100px;
    gap: 25px; }
    .subTitle__container-line {
      width: 60px;
      background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%); } }
@media screen and (max-width: 1000px) {
  .subTitle__container {
    padding-left: 80px;
    gap: 20px; }
    .subTitle__container-line {
      width: 40px;
      background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%); } }
@media screen and (max-width: 800px) {
  .subTitle__container {
    padding-left: 30px;
    gap: 10px; }
    .subTitle__container-line {
      width: 30px;
      background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%); }
    .subTitle__container-description {
      font-size: 16px; } }
@media screen and (max-width: 500px) {
  .subTitle__container {
    padding: 0 30px;
    gap: 10px;
    text-align: center;
    display: flex;
    justify-content: center; }
    .subTitle__container-line {
      width: 30px;
      background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%); }
      .subTitle__container-line--second {
        display: block; }
    .subTitle__container-description {
      font-size: 16px; } }

/*# sourceMappingURL=SubTitle.css.map */
