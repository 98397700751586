@import "../../main/style.scss";




.about-us {
	width: 100%;
	margin-bottom: $section-margin;
	padding-top: 140px;

	&__box {
		width: 100%;
		height: 40px;
		padding-left: $secondry-padding;
		@include display-center(space-between, center);



		&-subTitle {
			@include font(#fff, 24px, 500, normal, normal);
			transform: rotate(-90deg);
			position: absolute;
		}

		&-line {
			width: 24px;
			height: 1px;
			background: $main;
			margin-top: 40px;
			margin-bottom: 20px;
		}

		&-description {
			width: 70%;
			@include secondary-description();
		}

		.about-us__position {
			width: 24px;
			@include display-center(flex-start, center);
			flex-direction: column;
		}
	}

	&__box2 {
		margin-top: $section-margin;

		&-container {
			width: 100%;
			padding-left: $secondry-padding;
			padding-right: $main-padding;
			@include display-center(space-between, flex-start);
		}

		&-cart {
			width: auto;
			height: auto;
			@include display-center(flex-start, center);
			flex-direction: column;
			transition: .4s;
			transform: translateY(0);
			cursor: pointer;

			&:hover {
				transform: translateY(-10px);
			}

			.animation-div {
				@include display-center(flex-start, center);
				flex-direction: column;
			}

			.about-us__iconBox {
				width: 200px;
				height: 200px;
				background-color: #121212;
				margin-bottom: 35px;
				@include display-center(center, center);

			}	

			.about-us__cart-description {
				width: 250px;
				text-align: center;
				text-transform: uppercase;
				@include secondary-description();
			}
		}

	}

	&__box3 {
		margin-top: $section-margin;
		padding-right: $main-padding;

		&-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			.about-us__button {
				width: 35px;
				height: 35px;
				border: 1px solid  rgba($color: $white, $alpha: 0.2);
				@include display-center(center, center);
				cursor: pointer;
				position: relative;
				bottom: 15px;

			

				&::after {
					content: "";
					width: 120px;
					height: 120px;
					background: $main;
					filter: blur(50px);
					position: absolute;
					z-index: -1;
					transform: scale(1);
					transition: 0.5s;
				}

				&--open {
					&::after { 
						transform: scale(0.7) !important;
						transition: 0.5s !important;
					}
				}

				&-line {
					position: absolute;
					width: 18px;
					height: 1px;
					background-color: $white;
					transition: 0.2s;

					&:first-child {
						transform: rotate(90deg);
					}

					&--active {
						width: 1px;
						opacity: 0;
						transition: 0.3s;
					}
				}

			
			}
		}


		&-description {
			width: 100%;
			margin-top: 40px;
			padding-left: $secondry-padding;
			overflow: hidden;
			transition: all 300ms cubic-bezier(0.420, 0.000, 0.580, 1.000); 
			transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000); 

			p {
				width: 100%;
				@include secondary-subTitle();
			}

			.about-us__margin {
				margin-top: 20px ;
			}

			.about-us__margin2 {
				margin-top: 40px ;
				font-weight: 700;
				span {
					font-weight: 400;
				}
			}
		}

		&-description--active {
			transition: all 300ms cubic-bezier(0.420, 0.000, 0.580, 1.000); 
			transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000);
		}

	}

}

@media screen and (max-width: 1400px) {
	.about-us {
		&__box {
			&-description {
				width: 85%;
				@include secondary-description();
			}
	
		}
	
		&__box2 {
			&-cart {
				.about-us__iconBox {
					width: 160px;
					height: 160px;
					background-color: #121212;
					margin-bottom: 35px;
					@include display-center(center, center);

					.icon-box  {
						width: 160px !important;
						height: 160px !important;
						min-width: 160px !important;
						min-height: 160px !important;

						.icon-box__icon {
							width: 100px !important;
							height: 100px !important;
						}
					}
				}
	
				.about-us__cart-description {
					width: 165px;
					text-align: center;
					text-transform: uppercase;
					@include secondary-description();
				}
			}
		}
	}
}


@media screen and (max-width: 1200px) {
	$main-padding: 100px;
    $secondry-padding: 185px;

	.about-us {
		.title__container {
			&-title {
				width: 400px;
			}
		}
	

		&__box {
			height: auto;
			flex-wrap: wrap;
			gap: 40px;
			padding-left:  $secondry-padding;
			padding-right: $main-padding;
		}
		&__box {
			&-description {
				margin-top: 3px;
				width: 330px;
				@include secondary-description();
			}
	
		}
	
		&__box2 {
			&-container {
				padding-left:  $secondry-padding;
				padding-right: $main-padding;
			}

			&-cart {
				.about-us__iconBox {
					width: 150px;
					height: 150px;
					background-color: #121212;
					margin-bottom: 35px;
					@include display-center(center, center);

					.icon-box  {
						width: 150px !important;
						height: 150px !important;
						min-width: 150px !important;
						min-height: 150px !important;
					}
				}
			}
		}

		&__box3 {
			padding-right: $main-padding;
			&-description {
				padding-left: $secondry-padding;
			}
		}
	}
}



@media screen and (max-width: 1000px) {
	$main-padding: 80px;
    $secondry-padding: 140px;

	.about-us {
		.title__container {
			&-title {
				width: 360px;
			}
		}

		&__box {
			height: auto;
			flex-wrap: wrap;
			gap: 40px;
			padding-left:  $secondry-padding;
			padding-right: $main-padding;
		}


		&__box {
			&-column {
				display: flex;
			}

			&-subTitle {
				width: 25px;
				transform: rotate(0deg);
			}

			&-line {
				margin-top: 50px;
			}

			&-description {
				width: 100%;
				@include secondary-description();
				margin-left: 20px;
			}
	
		}
	
		&__box2 {
			&-container {
				padding-left:  $secondry-padding;
				padding-right: $main-padding;


				display: grid;
				align-items: flex-start;
				justify-content: center;
				gap: 60px;
				grid-template-columns: auto auto;
			}
			
			&-cart {
				.about-us__iconBox {
					width: 150px;
					height: 150px;
					background-color: #121212;
					margin-bottom: 35px;
					@include display-center(center, center);

					.icon-box  {
						width: 150px !important;
						height: 150px !important;
						min-width: 150px !important;
						min-height: 150px !important;
					}
				}
				
			.about-us__cart-description {
				width: 250px;
			}

			}
		}
		
		&__box3 {
			padding-right: $main-padding;
			&-description {
				padding-left: $secondry-padding;
			}
		}
	}
}


@media screen and (max-width: 800px) {
	$main-padding: 30px;
    $secondry-padding: 70px;

	.about-us {
		&__box {
			gap: 30px;
			padding-left:  $secondry-padding;
			padding-right: $main-padding;
		}


		&__box {
			position: relative;
			&-subTitle {
				@include font(#fff, 20px, 500, normal, normal);
			}

			&-description {
				width: 100%;
				@include secondary-description();
				margin-left: 20px;
			}

			
			&-line {
				margin-top: 30px;
			}
	
		}
	
		&__box2 {
			margin-top: 60px;
			&-container {
				padding-left:  $secondry-padding;
				padding-right: $main-padding;
				gap: 35px;
			}
			
			&-cart {
				.about-us__iconBox {
					width: 140px;
					height: 140px;
					margin-bottom: 25px;
					.icon-box  {
						width: 140px !important;
						height: 140px !important;
						min-width: 140px !important;
						min-height: 140px !important;
					}
				}
				
			.about-us__cart-description {
				width: 200px;
			}

			}
		}
		
		&__box3 {
			margin-top: 60px;
			padding-right: $main-padding;
			&-description {
				padding-left: $secondry-padding;
			}
		}
	}
}


@media screen and(max-width: 600px) {
	.about-us {
		margin-bottom: 0px;
	}
}

@media screen and (max-width: 500px) {
	$main-padding: 30px;
    $secondry-padding: 30px;

	.about-us {
		&__box {
			gap: 30px;
			padding-left:  $secondry-padding;
			padding-right: $main-padding;
		}

		.title__container {
			display: flex;
			justify-content: center;
			align-items: center;

			&-title {
				width: auto;
				text-align: center;
			}
		}

		&__box {
			position: relative;

			.about-us__position {
				width: 100%;
			}

			&-column {
				width: 100%;
				flex-direction: column;
				justify-content: center
			}

			&-subTitle {
				@include font(#fff, 20px, 500, normal, normal);
			}

			&-description {
				width: 100%;
				@include secondary-description();
				margin-left: 0px;
				text-align: center;
				padding: 0 20px;
			}
			
			&-line {
				margin-top: 30px;
			}
		}
	
		&__box2 {
			&-container {
				padding-left:  $secondry-padding;
				padding-right: $main-padding;
				@include display-center(center, center);
				flex-direction: column;
			}

			
			&-cart {
				.animation-div {
					div {
						@include display-center(center, center);
		
						flex-direction: column;
						text-align: center;
					}
				}
				.about-us__cart-description {
					width: 60%;
				}
			}
		}
		
		&__box3 {
			width: 100%;
			margin-top: 60px;
			padding-right: 0px;
			@include display-center(center, center);
			flex-direction: column;
			.subTitle__container {
				padding: 0px;
			}
			&-top {
				width: 100%;
				height: auto;
				@include display-center(center, center);
				flex-direction: column;
				padding: 0 30px;
				.animation-div {
					&:last-child {
						@include display-center(center, center);
		
						flex-direction: column;
						text-align: center;
					}
	
				}
				.about-us__button {
					bottom: 0px;
				}
			}
			&-description {
				padding-left: $secondry-padding;
				padding-right: $secondry-padding;
			}
		}
	}
}