@import "../../main/style.scss";

.footer {
	width: 100%;
	padding: $section-margin $main-padding 35px;
	background-color: $secondary-dark;
	padding-bottom: 35px;
	overflow: hidden;
	position: relative;
	z-index: 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 12;
	
	&__top {
		margin-bottom: $section-margin;
		width: 100%;
		height: auto;
		@include display-center(space-between, flex-start);
		position: relative;

		&-logo {
			position: relative;
			@include display-center(flex-start, center);
			
			svg {
				z-index: 1;
				cursor: pointer;
			}
			&::after {
				content: "";
				width: 120px;
				height: 120px;
				background: $main;
				filter: blur(50px);
				position: absolute;
				z-index: -1;
				left: -5%;
				top: -110%;
			}
		}

		&-description {
			width: 335px;
			margin-top: 25px;
			@include secondary-subTitle();
		}

		.social__media-wrapper {
			margin-top: 35px;
		}

		&-nav {
			width: auto;
			height: 200px;

			
			.footer__nav {
				.footer__list {
					display: flex;
					flex-direction: column;
					row-gap: 10px;
					
					.footer__item {
						cursor: pointer;
                        @include font(rgba(255, 255, 255, 0.6), 16px, 500, normal, 0);
						transition: .4s;

						&:hover{
							color: $white;
							transition: .4s;
						}
					}
				}
			}
		}

		
		&-form {
			width: auto;
			// height: 200px;

			.footer__top-input {
				cursor: pointer;
				
				input {
					-webkit-box-shadow: inset 0 0 0 50px $ligth-dark;
					-webkit-text-fill-color: $white;
				}
			}
		}

		&-subTitle {
			@include secondary-description();
			font-weight: 500;
			padding-bottom: 25px;
		}
		.error-message {
			@include secondary-description();
			color: $error-color;
		}

		&-input {
			width: 400px;
			height: 60px;
			background-color: $ligth-dark;
			display: flex;
			
			input {
				width: 340px;
				height: 100%;
				background-color: transparent;
				border: none;
				outline: none;
				padding: 0 15px;

				@include secondary-subTitle();
				color: rgba($color: #fff, $alpha: 0.6);
			}

			button {
				width: 60px;
				height: 60px;
				background: $main;
				border: none;
				cursor: pointer;
				@include display-center(center, center);

			}
		}
	}

	&__bottom {
		width: 100%;
		height: 50px;
		@include secondary-subTitle();
		color: rgba($color: #fff, $alpha: 0.6);

		&-line {
			width: 100%;
			height: 1px;
			background: $main;
		}

		p {
			text-align: center;
			margin-top: 35px;
		}

	}
}

@media screen and (max-width: 1200px) {
	$main-padding: 100px;
    $secondry-padding: 185px;

	.footer {
		padding: $section-margin $main-padding 35px;
		background-color: $secondary-dark;
		&__top {
			flex-wrap: wrap;
			gap: 80px;
		}
	}
	
}


@media screen and (max-width: 1000px) {
	$main-padding: 80px;
    $secondry-padding: 140px;

	.footer {
		padding: $section-margin $main-padding 35px;
		background-color: $secondary-dark;
		&__top {
			justify-content: space-around;
			flex-wrap: wrap;
			gap: 80px 100px;

			&-form {
				text-align: center;
			}
		}
	}
}




@media screen and (max-width: 800px) {
	$main-padding: 30px;
    $secondry-padding: 70px;

	.footer {
		padding: $section-margin $main-padding 35px;
		background-color: $secondary-dark;
		&__top {
			flex-wrap: wrap;
			gap: 80px 100px;


			&-description {
				width: 300px;
				margin-top: 25px;
				@include secondary-subTitle();
			}
		}
	}
	
}


@media screen and (max-width: 560px) {
	$main-padding: 30px;
    $secondry-padding: 30px;

	.footer {
		padding: $section-margin $main-padding 35px;
		background-color: $secondary-dark;
		&__top {
			width: 100%;
			&-description {
				width: 100%;
				text-align: center;
			}
			&-nav {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;

				.footer__nav {
					.footer__list {
						align-items: center;
					}
				}
			}

			&-form {
				width: 100%;
				height: auto;
				@include display-center(center, center);
				flex-direction: column;
				text-align: center;
			}

			&-input {
				width: 400px;
				height: 50px;
				background-color: $ligth-dark;
				display: flex;
				
				input {
					width: 350px;
					height: 50px;
					background-color: transparent;
					border: none;
					outline: none;
					padding: 0 15px;
	
					@include secondary-subTitle();
                    -webkit-text-fill-color: $white;
					-webkit-box-shadow: inset 0 0 0 50px $ligth-dark;
				}
	
				div {
					width: 50px;
					height: 50px;
					background: $main;
					@include display-center(center, center);
				}

				button {
					width: 50px;
					height: 50px;
				}
			}

			&-left {
				width: 100%;
				@include display-center(center, center);
				flex-direction: column;
			}
		}
	}
 }	


 @media screen and (max-width: 500px) {
	.footer  {
		&__top {
			&-logo {
				&::after {
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}

			&-input {
				display: flex;
				justify-content: space-between;
				width: 100%;
				height: 50px;
				
				input {
					width: calc(100% - 50px);
					height: 100%;
				}
	
				div {
					min-width: 50px;
					height: 50px;
				}

				button {
					width: 50px;
					height: 50px;

					& svg {
						width: 28px;
						height: 28px;
					}
				}
			}
		}
	}
 }