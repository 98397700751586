@import "./../../../main/style.scss";

.header__menu {
    display: flex;
    column-gap: 15px;
    z-index: 12;
  
    .menu__text {
        font-variant: all-small-caps;
        cursor: pointer;
        @include font($white, 18px, 600, normal, 0);
    }
  
  
    .menu__icon {
        width: 30px;
        height: 30px;
        @include display-center(center, flex-end);
        flex-direction: column;
        row-gap: 8px;
        cursor: pointer;
        overflow: hidden;
        transition: .5s;
  
        &--bar {
            height: 1.5px;
            border-radius: 10px;
            background-color: $white;
        }
  
        &-line {
            position: relative;
            overflow: hidden;
            width: 100%;
            min-height: 1.5px;
            transform-origin: 100% 50%;
            transition: .3s;
  
            &:nth-child(3) {
                width: 21px;
            }
  
            &__inner {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $white;
  
                &:nth-child(2) {
                    transform: translateX(100%);
                }
            }
  
        }
  
        &:hover {
            & .menu__icon-line:nth-child(1) {
                .menu__icon-line__inner:nth-child(1) {
                    animation: slideInFromRight  .5s ease-in-out;
                }
                .menu__icon-line__inner:nth-child(2) {
                    animation: slideOutToLeft  .5s ease-in-out;
                }
            }
  
            & .menu__icon-line:nth-child(2) {
                .menu__icon-line__inner:nth-child(1) {
                    animation: slideInFromRight  .5s 80ms ease-in-out;
                }
                .menu__icon-line__inner:nth-child(2) {
                    animation: slideOutToLeft  .5s 80ms ease-in-out;
                }
            }
  
            & .menu__icon-line:nth-child(3) {
                
  
                .menu__icon-line__inner:nth-child(1) {
                    animation: slideMiniLineIn .5s 90ms ease-in-out;
                }
                .menu__icon-line__inner:nth-child(2) {
                    animation: slideMiniLineOut  .5s 90ms ease-in-out;
                }
            }
        }
    }
  
}


._menu-open {
    .header__menu {
        display: flex;
        text-align: center;
        justify-content: center;
        z-index: 12;

        .menu__text {
            transition: .3s;
        }

        .menu__icon {
            &-line {
                &:nth-child(1) {
                    transform: translate(-15%, -400%) rotate(-45deg);
                    transition: .3s;
                }

                &:nth-child(2) {
                    transform: translate(-15%, 350%) rotate(45deg);
                    transition: .3s;    
                }

                &:nth-child(3) {    
                    position: absolute; 
                    // bottom: 19.5px;
                    bottom: 44.5px;
                    transition: 1s;
                    width: calc(100% - 200px);  
                    background: transparent;
                    min-height: 1.5px;
                    z-index: 5;
                    animation: hide 1.2s .8s forwards;

                    @keyframes hide {
                        0% { display: block; bottom: 44.5px; }
                        100% { display: none; bottom: -100vh; }
                    }
                }
            }
        }
    }
}

// Burger Animation
.header__menu-overlay {
    top: -100vh;
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    opacity: 0;
    z-index: -1;
}

// .header__menu-nav {
//     .header__menu-list {
//         display: flex;
//         flex-direction: column;
//         row-gap: 20px;

//         .header__menu-item {
//             display: flex;
//             align-items: center;
//             transition: .3s;
//             user-select: none;
            

//             &:hover {
//                 transform: translateX(-20px);
//                 transition: .3s;

//                 .header__menu-item__rhombus {
//                     transform: rotate(-45deg) scale(1);
//                     transition: .3s;
//                 }

//                 p {
//                     &::before {
//                         left: -90px;
//                         width: 94.3px;   
//                         transition: .3s;
//                     }
//                 }
//             }

//             &__order {
//                 width: 17px;
//                 @include font($white, 12px, 500, normal, 0);
//                 margin-right: 5px;
//             }

//             &__rhombus {
//                 margin-right: 90px;
//                 width: 6px;
//                 height: 6px;
//                 background-color: $white;
//                 transform: rotate(-45deg) scale(0);
//                 transition: .3s;
//             }

//             p {
//                 position: relative;
//                 cursor: pointer;
//                 @include font($white, 24px, 600, normal, 0);

//                 &::before {
//                     content: "";
//                     position: absolute;
//                     left: 0px;
//                     width: 0px;   
//                     min-height: 2px;
//                     top: 50%;
//                     transform: translateY(-50%);
//                     background-color: $white;
//                     transition: .3s;
//                 }
//             }
//         }
//     }
// }


// ._menu-open {
//     .header__menu-overlay {
//         animation: burgerOverlayAnimation 1s .8s forwards;
//         display: block;
//         position: fixed;
//         width: 100vw;
//         height: 100vh;
//         background-color: rgb(0, 0, 0);
//         z-index: 11;
//         opacity: 1;

//         @keyframes burgerOverlayAnimation {
//             0% { top: -100vh; }
//             100% { top: 0px; }
//         }
//     }
// }


@media screen and (max-width: 1000px) {
    $main-padding: 80px;

    ._menu-open {
        .header__menu {
            .menu__icon {
                &-line {
                    &:nth-child(3) {    
                        width: calc(100% - 160px);  
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 800px) {
    $main-padding: 30px;
    
    ._menu-open {
        .header__menu {
            .menu__icon {
                &-line {
                    &:nth-child(3) {    
                        width: calc(100% - 60px);  
                    }
                }
            }
        }
    }
}



// Burger Menu Animation
@keyframes slideInFromRight  {
    0% {transform: translateX(140%);} 
    100% {transform: translateX(0%);}
}

@keyframes slideOutToLeft  {
    0% { transform: translateX(0%); } 
    100% { transform: translateX(-140%); }
}

@keyframes slideMiniLineIn {
    0% { transform: translateX(160%); }
    100% { transform: translateX(0%); }
}

@keyframes slideMiniLineOut  {
    0% { transform: translateX(0%); } 
    100% { transform: translateX(-160%); }
}
