@import "./mixins";
@import "./variables";

body {
  background-color: $black;
  overflow-x: hidden;

  &._body-hidden {
    overflow: hidden;
  }
}


::selection {
  background: rgba(255, 255, 255, 0.2);
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $black;
}

::-webkit-scrollbar-thumb {
  background: $main;
}


.App {
  overflow-x: hidden;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* змінюйте duration та timing function на ваш вибір */
}

.fade-in.visible {
  opacity: 1;
}



// ::selection {
//   background: rgba(0, 109, 192, 0.50);
// }



.rolling__block {
  position: relative;

  height: 23px;
  overflow: hidden;
  transition: .5s;

  &:hover {
      transition: .5s;

      .rolling__block--before {
          transform: translateY(-23px);
      }
      .rolling__block--after {
          transform: translateY(-23px);
      }
  }

  .rolling__block--before, .rolling__block--after {
      transition: .5s;
  }

  .rolling__block--after {
      position: absolute;
  }
}


.social__media-wrapper {
  display: flex;
  column-gap: 20px;
}


@media screen and (max-width: 600px) {
  .social__media-wrapper  {
    column-gap: 15px;
  }
  .text-animation {
    display: none;
  }
}