@import "../../main/style.scss";

.portfolio {
  width: 100%;
  // margin-bottom: $section-margin;
  padding-top: 140px;

  &__box {
    width: 100%;
    height: auto;
    @include display-center(center, center);
    color: $white;

    .portfolio__slider {
      width: 100%;
      height: 100%;
      @include display-center(center, center);
      transition: 0.5s;

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }

      .swiper-button-prev {
        left: 30px; 
      }

      .swiper-button-next {
        right: 30px; 
      }

      .swiper-button-prev::after {
        content: ''; 
      }

      .swiper-button-next::after {
        content: '';
      }

      .swiper-button-prev:hover,
      .swiper-button-next:hover {
        background: $main; 
		opacity: 0.5;
		transition: background-color 0.3s ease; 
	}
    }

    .portfolio__card {
      width: 700px !important;
      &-img {
        cursor: grab;
        width: 100%;
        position: relative;
        z-index: -1;
        @include display-center(flex-end, flex-end);

        img {
          width: 100%;
        }

        &::after {
          content: "";
          width: 200px;
          height: 100px;
          background-size: contain;
          background-position: bottom;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 3;
          margin-bottom: 30px;
          margin-right: 30px;
        }
      }

      &-img1 {
        &::after {
          background-image: url(../img/logo1.svg);
        }
      }

      &-img2 {
        &::after {
          background-image: url(../img/logo2.svg);
        }
      }

      &-img3 {
        &::after {
          background-image: url(../img/logo3.svg);
        }
      }

      &-img4 {
        &::after {
          background-image: url(../img/logo4.svg);
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .portfolio {
    .title__container {
      &-title {
        width: 400px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .portfolio {
    .title__container {
      &-title {
        width: 360px;
      }
    }
    &__box {
      .portfolio__card {
        width: 500px !important;
        &-img {
          &::after {
            content: "";
            width: 170px;
            height: 100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .portfolio {
    .title__container {
      &-title {
        width: 300px;
      }
    }
    &__box {
      .portfolio__card {
        width: 350px !important;
        &-img {
          &::after {
            content: "";
            width: 150px;
            height: 100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .portfolio {
    .title__container {
			display: flex;
			justify-content: center;
			align-items: center;

			&-title {
				width: auto;
				text-align: center;
			}
		}
    &__box {
      display: flex;
      justify-content: center;
	  .portfolio__slider {
		width: 100%;
		height: 100%;
		@include display-center(center, center);
		transition: 0.5s;
  
		.swiper-button-prev,
		.swiper-button-next {
			width: 50px; 
			height: 50px;
			background-color: rgba(255, 255, 255, 0.5); 
			border-radius: 50%; 
			z-index: 10;
			cursor: pointer; 
			transition: background-color 0.3s ease; 
			display: flex;
			transition: 0.5s;

			svg {
				width: 20px ;
			}
		}
		}
      .portfolio__card {
        width: 300px !important;
        &-img {
          &::after {
            content: "";
            width: 100px;
            height: 100px;
            margin-bottom: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
