@import "./../../../main/style.scss";

.icon-box {
    position: relative;
    border: 1px solid transparent;
    border-image: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%);
    border-image-slice: 1;
    @include display-center(center, center);


    &--medium {
        width: 84px;
        height: 84px;
        min-width: 84px;
        min-height: 84px;

        .icon-box__icon {
            width: 54px;
            height: 54px;
        }
    }
    
    &--black {
        background-color: rgba(0, 0, 0, 0.5);
    }

    &--white {
        background-color: rgba($white, 0.5);
        border: none;
    }
    
    .icon-box__icon {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @include display-center(center, center);
        z-index: 2;
    }

    .icon-box__circle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%);
        border-radius: 50%;
        filter: blur(30px);
        z-index: 1;
    }
}


@media screen and (max-width: 1400px) {
    .icon-box {
        &--medium {
            width: 70px;
            height: 70px;
            min-width: 70px;
            min-height: 70px;

            .icon-box__icon {
                width: 44px;
                height: 44px;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .icon-box {
        &--medium {
            width: 55px;
            height: 55px;
            min-width: 55px;
            min-height: 55px;

            .icon-box__icon {
                width: 32px;
                height: 32px;
            }
        }
    }
}