@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

:focus, :active {
  outline: none; }

a:focus, a:active {
  outline: none; }

input, button, textarea {
  font-family: inherit; }

button {
  cursor: pointer; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

a, a:visited {
  text-decoration: none; }

a:hover {
  text-decoration: none; }

ul li {
  list-style: none; }

body {
  background-color: #000;
  overflow-x: hidden; }
  body._body-hidden {
    overflow: hidden; }

::selection {
  background: rgba(255, 255, 255, 0.2); }

::-webkit-scrollbar {
  width: 10px; }

::-webkit-scrollbar-track {
  background: #000; }

::-webkit-scrollbar-thumb {
  background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%); }

.App {
  overflow-x: hidden; }

.rolling__block {
  position: relative;
  height: 23px;
  overflow: hidden;
  transition: .5s; }
  .rolling__block:hover {
    transition: .5s; }
    .rolling__block:hover .rolling__block--before {
      transform: translateY(-23px); }
    .rolling__block:hover .rolling__block--after {
      transform: translateY(-23px); }
  .rolling__block .rolling__block--before, .rolling__block .rolling__block--after {
    transition: .5s; }
  .rolling__block .rolling__block--after {
    position: absolute; }

.social__media-wrapper {
  display: flex;
  column-gap: 20px; }

@media screen and (max-width: 600px) {
  .social__media-wrapper {
    column-gap: 15px; }

  .text-animation {
    display: none; } }
.App {
  overflow-x: hidden; }

.title__container {
  padding: 0 230px;
  margin-bottom: 80px; }
  .title__container-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    font-style: normal;
    color: #fff;
    font-weight: 700;
    line-height: normal;
    white-space: pre-line;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    word-wrap: break-word; }
    .title__container-title::after {
      content: attr(data-after-content);
      position: absolute;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.2);
      font-family: 'Montserrat', sans-serif;
      font-size: 128px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 150px;
      margin-top: 30px; }
    .title__container-title::before {
      content: "";
      width: 120px;
      height: 120px;
      background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%);
      filter: blur(50px);
      position: absolute;
      top: -10%;
      left: -10%;
      z-index: -1; }

@media screen and (max-width: 1200px) {
  .title__container {
    padding: 0 185px; }
    .title__container-title {
      font-size: 40px; }
      .title__container-title::after {
        font-size: 100px;
        margin-left: 100px;
        margin-top: 20px; } }
@media screen and (max-width: 1000px) {
  .title__container {
    padding: 0 140px; }
    .title__container-title {
      font-size: 36px; }
      .title__container-title::after {
        font-size: 100px;
        margin-left: 60px;
        margin-top: 20px; } }
@media screen and (max-width: 800px) {
  .title__container {
    padding: 0 70px; }
    .title__container-title {
      font-size: 32px; }
      .title__container-title::after {
        font-size: 70px;
        margin-left: 30px; } }
@media screen and (max-width: 500px) {
  .title__container {
    padding: 0 30px;
    display: flex;
    justify-content: center;
    text-align: center; }
    .title__container-title {
      text-align: center;
      font-size: 32px; }
      .title__container-title::after {
        text-align: center;
        font-size: 44px;
        margin: 0;
        padding: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .title__container-title::before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }

/*# sourceMappingURL=title.css.map */
