@import "./../../main/style.scss";

.contacts {
    padding-top: $section-margin;
    margin-bottom: $section-margin;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__animation {
        width: 1000px;
        height: 500px;
        position: absolute;
        left: -15%;
        margin-top: 600px;
        cursor: none;
        z-index: -1;
        opacity: 0.4;

        canvas {
            transform: scale(1.5);
            cursor: none;
        }
    }

    &__container {
        width: 100%;
    }

    .title__container {
        &-title {
            max-width: 960px;
        }
    }

    .error-message {
        @include font($error-color, 14px, 400, normal, 0);
        margin-top: 5px;
    }

    &__wrapper {
        padding: 0 $main-padding 0 $secondry-padding;
        @include display-center(space-between, flex-start);

        column-gap: 40px;


        .contacts__info {
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            &-email {
                @include font($white, 15px, 600, normal, 0);
                text-transform: uppercase;
                transition: .4s
            }

            &-phone {
                @include font($white, 24px, 600, normal, 0);
                transition: .4s
            }

            &--item:hover {
                transition: .4s;
                color: $grey;
            }
        }

        .animation-div {
            &:last-child {
                width: 75% !important;
            }
        }


        .contacts__form {
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            border-top: 2px solid rgba(255, 255, 255, 0.2);

            &-row {
                display: flex;
                justify-content: space-between;
                column-gap: 24px;
            }


            &-box {
                ::-webkit-scrollbar {
                    width: 5px;
                    border-radius: 5px;
                }
                ::-webkit-scrollbar-track {
                    transform: translateX(-55px);
                    background: transparent;
                }
                ::-webkit-scrollbar-thumb {
                    transform: translateX(-55px);
                    background: gba(255, 255, 255, 0.6);
                    border-radius: 5px;
                }


                &__message {
                    position: relative;
                    overflow: hidden;
                    height: 260px;  
                    background-color: $ligth-dark;
                }

                .gradient-btn {
                    margin: 30px auto 0px auto;
                }

                .error-message {
                    color: $error-color;
                }
                  


                &__heading {
                    margin-bottom: 10px;
                    @include font($white, 16px, 500, normal, 0);
                    display: flex;
                    column-gap: 10px;

                    
                    
                    &-clue {
                        position: relative;
                        width: 18px;
                        height: 18px;
                        background-color: $white;
                        border-radius: 50%;
                        @include display-center(center, center);

                        
                        .contacts__form-question-icon {
                            min-width: 18px;
                            min-height: 18px;
                            background-color: $white;
                            border-radius: 50%;
                            @include display-center(center, center);
                            z-index: 1;

                            &--main {
                                transition: .4s;
                                cursor: help;
                            }
                        }


                        .contacts__form-clue {
                            padding: 0 15px;
                            position: absolute; 
                            top: -60px;
                            width: 200px;
                            height: 50px;
                            background-color: $ligth-dark;
                            // backdrop-filter: blur(5px);
                            @include display-center(center, center);
                            column-gap: 10px;
                            border: 1px solid $main;
                            overflow: hidden;
                            opacity: 0;
                            transform: translateY(-10px);
                            transition: .4s;


                            &._open {
                                opacity: 1;
                                transform: translateY(0px);
                                transition: .4s;
                            }
                        

                            &::before, &::after {
                                content: "";
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 70px;
                                height: 70px;
                                border-radius: 50%;
                                background: $main;
                                filter: blur(50px);
                                z-index: 1;
                            }

                            &::before {
                                left: -30px;
                            }

                            &::after {
                                right: -30px;
                            }



                            span {
                                @include font($white, 14px, 400, normal, 0);
                                z-index: 1;
                            }
                        }
                    }
                }

                &__input {
                    padding: 0 15px;
                    height: 60px;
                    width: 400px;
                    background-color: $ligth-dark;
                    outline: none;
                    border: none;
                    @include font($white, 16px, 500, normal, 0);
                    -webkit-box-shadow: inset 0 0 0 50px $ligth-dark;
                    -webkit-text-fill-color: $white;


                    &::placeholder {
                        color: rgba(255, 255, 255, 0.6);
                    }

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0; 
                    }

                    &[type=number] {
                        -moz-appearance:textfield; 
                    }
                }

                &__textarea {
                    padding: 0px;
                    padding-right: 5px;
                    margin: 15px 10px 15px 15px;
                    width: calc(100% - 30px);
                    height: 230px;
                    resize: none;
                    outline: none;
                    overflow-y: auto;
                }
            }
        }
    }
}


@media screen and (max-width: 1400px) {
    .contacts {
        &__wrapper {
            column-gap: 35px;
            width: 100%;
            .contacts__info {
                width: 230px;
                display: flex;
                flex-direction: column;
                row-gap: 20px;
    
                &-email {
                    @include font($white, 15px, 600, normal, 0);
                    text-transform: uppercase;
                }
    
                &-phone {
                    @include font($white, 24px, 600, normal, 0);
                }
            }

            .animation-div {
                &:last-child {
                    width: 70% !important;
                }
            }


            .contacts__form {
                width: 100%;
                padding-top: 50px;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                border-top: 2px solid rgba(255, 255, 255, 0.2);

    
                &-row {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    column-gap: 24px;
                }
    
    
                &-box {
                    width: 100%;
                    &__message {
                        height: 260px;  
                    }
    
                    .gradient-btn {
                        margin: 30px auto 0px auto;
                        width: 100%;
                        height: 50px;
                        column-gap: 20px;
                    }
    
                    &__input {
                        width: 100%;
                    }
    
                    &__textarea {
                        padding-right: 5px;
                        margin: 15px 10px 15px 15px;
                        width: calc(100% - 30px);
                        height: 230px;
                    }
                }
            }
        } 
    }
}


@media screen and (max-width: 1200px) {
    $main-padding: 100px;
    $secondry-padding: 185px;

    .contacts {
        &__wrapper {
            padding: 0 $main-padding 0 $secondry-padding;

            .contacts__info {
                width: 215px;
                row-gap: 15px;
                &-email{
                    font-size: 14px;
                }

                &-phone {
                    font-size: 22px;
                }
            }

            
            .animation-div {
                &:last-child {
                    width: 66% !important;
                }
            }


            .contacts__form {
                .contacts__form-box {
                    &__input {
                        height: 50px;
                    }

                    &__message {
                        height: 230px;  
                    }

                    &__textarea {
                        padding-right: 5px;
                        margin: 15px 10px 15px 15px;
                        width: calc(100% - 30px);
                        height: 200px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    $main-padding: 80px;
    $secondry-padding: 140px;


    .contacts {
        &__animation {
            display: none !important;
        }
        
        // .title__container {
        //     &-title {
        //         width: 500px;
        //     }
        // }
    

        .animation-div {
            width: 100% !important;
        }
        &__wrapper {
            padding: 0 $main-padding 0 $secondry-padding;
            flex-direction: column;

            .animation-div {
                &:last-child {
                    width: 100% !important;
                }
            }
            .contacts__info {
                margin-bottom: 10px;
                width: 100%;
                @include display-center(center, center);
                flex-direction: row;
                column-gap: 50px;

                &-email, &-phone {
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    $main-padding: 30px;
    $secondry-padding: 70px;

    
    .contacts {
        // .title__container {
        //     &-title {
        //         width: 400px;
        //     }
        // }

        &__wrapper {
            padding: 0 $main-padding 0 $secondry-padding;

            .contacts__info {
                column-gap: 30px;
                &-email, &-phone {
                    font-size: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    $main-padding: 30px;
    $secondry-padding: 30px;

    .contacts {
        &__wrapper {
            padding: 0 $main-padding 0 $secondry-padding;

            .contacts__info {
                flex-direction: column;
                column-gap: 20px;

                &-email {
                    font-size: 16px;
                }

                &-phone {
                    font-size: 20px;
                }
            }


            .contacts__form {
                padding-top: 30px;

                &-row {
                    display: flex;
                    flex-direction: column;
                    row-gap: 30px;
                }
            }
        }
    }
}