@import "../../../main/style.scss";

.calculator-popup {
	width: 1000px;
	height: 500px;
	background-color: rgba($color: #121212, $alpha: 0.7);
	z-index: 4;
	position: fixed;
	left: 50%;
	top: -100%;
	transform: translate(-50%, -50%);
	@include display-center(center, center);
	border: 1px solid transparent;
	border-image: $main;
	border-image-slice: 1;
	backdrop-filter: blur(60px);
	overflow: hidden;
	transition: all 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000); /* easeOutCirc */
	z-index: 12;

	transition-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000); /* easeOutCirc */
	&__element1 {
		width: 200px;
		height: 200px;
		background: $main;
		filter: blur(100px);
		position: absolute;
		left: 0%;
		top: 0%;
		z-index: 2;
	}

	&__element2 {
		width: 200px;
		height: 200px;
		background: $main;
		filter: blur(100px);
	}

	
	&__element3 {
		width: 200px;
		height: 200px;
		background: $main;
		filter: blur(100px);
		position: absolute;
		right: 0%;
		bottom: 0%;
	}


	&__close {
		width: 40px;
		height: 40px;
		position: absolute;
		right: 0%;
		top: 0%;
		margin-top: 10px;
		margin-right: 10px;
		@include display-center(center, center);
		cursor: pointer;
	}

	&__container {
		width: 100%;
		position: absolute;
		@include display-center(center, center);
		flex-direction: column;
		z-index: 2;

		&-box {
			width: 80%;
			@include display-center(center, center);
			flex-wrap: wrap;
			gap: 20px;
			position: absolute;

		}

		&-box2 {
			flex-direction: column;
		}

		&-buttons {
			width: 350px;
			margin-top: 50px;
			position: relative;
			top: 100px;
		}

		&-back {
			width: 100%;
			@include font($white, 14px, 400, normal, 0);
			margin-top: 10px;
			text-align: center;

		}
	}

	&__item {
		display: flex;
		cursor: pointer ;
 
		&-icon {
			position: relative;
			left: 10px;
			z-index: 2;

			svg {

				.path-opacity {
					opacity: 1;
				}

				.path-opacity {
					opacity: 1;
				}
				.svg-color {
					display: flex;
					
				}
				
			}
		}

		&-input {
			width: 200px;
			height: 44px;
			background-color: #121212;
			position: relative;
			right: 10px;
			@include display-center(flex-start, center);


			p {
				@include font($white, 18px, 400, normal, 0);
				margin-left: 30px;
				background: $main;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			input {
				width: 100%;
				height: 100%;
				background: transparent;
				border: none;

				outline: none;
				margin-left: 30px;
				@include font($white, 18px, 400, normal, 0);
				background: $main;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
				margin-right: 30px;

				&::placeholder {
					background: $main;
                	background-clip: text;
                	-webkit-background-clip: text;
                	-webkit-text-fill-color: transparent;
				}
			}
		}
	}

	&__title {
		@include font($white, 42px, 700, normal, 0);
		text-align: center;
		position: relative;
		bottom: 120px;
	}

	&__description {
		width: 70%;
		@include font($white, 20px, 600, normal, 0);
		text-align: center;
		margin-top: 15px;
		position: relative;
		bottom: 120px;
	}


}

.calculator-popup--open {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000); /* easeOutCirc */

	transition-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000); /* easeOutCirc */
}




@media screen and (max-width: 1100px) {
	.calculator-popup {
		width: 85%;
		
	&__container {
		&-box {
			width: 100%;
			gap: 15px;
			margin-top: 30px;
		}

		&-buttons {
			width: 300px;
			margin-top: 40px;
			position: relative;
			top: 100px;
		}
	}

	&__title {
		font-size: 40px;
	}

	&__description {
		font-size: 20px;
		margin-top: 10px;
	}

	}



}



@media screen and (max-width: 900px) {
	.calculator-popup {
		width: 95%;
	&__container {
		&-box {
			width: 90%;
			height: 200px;
			overflow-y: auto;
			gap: 15px;
			margin-top: 30px;
			
		}

		&-buttons {
			width: 300px;
			margin-top: 40px;
			position: relative;
			top: 120px;
		}
	}

	&__close {
		width: 30px;
		height: 30px;
		position: absolute;
		right: 0%;
		top: 0%;
		margin-top: 10px;
		margin-right: 10px;
		@include display-center(center, center);

		svg {
			width: 20px;
			height: 20px;
		}
	}

	&__title {
		font-size: 36px;
		position: relative;
		bottom: 130px;
		padding: 0 10px;
	}

	&__description {
		width: 95%;
		font-size: 18px;
		margin-top: 10px;
		position: relative;
		bottom: 135px;	
	}

	}

	

}



@media screen and (max-width: 600px) {
	.calculator-popup {
		
	&__container {
		&-box {
			width: 90%;
			height: 200px;
			overflow-y: auto;
		}

		&-buttons {
			width: 80%;
			margin-top: 40px;
			position: absolute;
			top: 140px;
		}
	}
	&__item {
		width: 100%;
		&-input {
			width: 100% !important;
			input {
				font-size: 16px;
			}
			
		}
	}

	&__close {
		width: 30px;
		height: 30px;
		position: absolute;
		right: 0%;
		top: 0%;
		margin-top: 10px;
		margin-right: 10px;
		@include display-center(center, center);

		svg {
			width: 20px;
			height: 20px;
		}
	}

	&__title {
		font-size: 28px;
		position: relative;
		bottom: 170px;
	}

	&__description {
		width: 95%;
		font-size: 16px;
		margin-top: 10px;
		position: relative;
		bottom: 175px;	
		text-align: center;
	}

	}

	

}



@media screen and (max-width: 400px) {
	.calculator-popup {

		&__item {
			&-input {
				input {
					font-size: 14px;
				}
				
			}
		}
	}

}



@media screen and (max-height: 520px) {
	.calculator-popup {
		height: 450px;
	}


}

@media screen and (max-height: 520px) and (max-width: 900px) {
	.calculator-popup {
		height: 450px;
		&__close {
			width: 30px;
			height: 30px;
			position: absolute;
			right: 0%;
			top: 0%;
			margin-top: 10px;
			margin-right: 10px;
			@include display-center(center, center);
	
			svg {
				width: 20px;
				height: 20px;
			}
		}
		
		&__container {
			&-buttons {
				position: absolute;
				top: 160px;
			}
		}

		&__title {
			font-size: 28px;
			position: relative;
			bottom: 175px;
		}
	
		&__description {
			width: 95%;
			font-size: 16px;
			margin-top: 10px;
			position: relative;
			bottom: 180px;	
			text-align: center;
		}
	}



}