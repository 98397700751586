@import "./../../main/style.scss";


.services {
    padding-top: $section-margin;

    // .title__container {
    //     &-title {
    //         width: 360px;
    //     }
    // }


    &__wrapper {
        @include display-center(space-between, center);
        flex-wrap: wrap;
        gap: 50px 30px;
        padding: 0 $main-padding 0 $secondry-padding;

        .services__card {
            padding: 20px 30px;
            width: 500px;
            height: 340px;
            background-color: $ligth-dark;
            transition: .4s;

            @include display-center(space-between, flex-start);
            flex-direction: column;


            // Border Gradient Style
            border-width: 1px;
            border-style: solid;
            border-image: $main;
            border-image-repeat: round;
            border-image-slice: 1;


            &:hover {
                transform: translateY(-10px);
                transition: .4s;
            }


            &--contact {
                background: $main;
            }

            &-main {
                .services__card-title {
                    margin: 20px 0 15px;
                    @include font($white, 24px, 700, normal, 0);
                }
    
                .services__card-description {
                    @include font($white, 16px, 400, normal, 0);
                }
            }
        }
    }
}



@media screen and (max-width: 1400px) {
    .services {
        &__wrapper {
            .services__card {
                padding: 20px;
                width: 410px;
                height: 300px;
    
                @include display-center(space-between, flex-start);
                flex-direction: column;
    
                &-main {
                    .services__card-title {
                        margin: 15px 0 10px;    
                        font-size: 22px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    $main-padding: 100px;
    $secondry-padding: 185px;


    .services {
        &__wrapper {
            gap: 50px 15px;
            padding: 0 $main-padding 0 $secondry-padding;

            .services__card {
                padding: 15px;
                width: 350px;
                height: 260px;
    
                &-main {
                    .services__card-title {
                        margin: 15px 0 10px;    
                        font-size: 20px;
                    }
                    
                    .services__card-description {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    $main-padding: 80px;
    $secondry-padding: 140px;


    .services {
        &__wrapper {
            padding: 0 $main-padding $section-margin $secondry-padding;
            gap: 30px 0px;

            .services__card {
                padding: 15px;
                width: 100%;
                height: 230px;
    
                &-main {
                    .services__card-title {
                        margin: 15px 0 10px;    
                        font-size: 20px;
                    }
                    
                    .services__card-description {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 800px) {
    $main-padding: 30px;
    $secondry-padding: 70px;


    .services {
        &__wrapper {
            padding: 0 $main-padding 0 $secondry-padding;
            gap: 30px 0px;


            .services__card {
                padding: 15px;
                width: 100%;
                height: 260px;
    
                &-main {
                    .services__card-title {
                        margin: 15px 0 10px;    
                        font-size: 20px;
                    }
                    
                    .services__card-description {
                        font-size: 14px;
                    }
                }
            }
        }
    }
} 


@media screen and (max-width: 500px) {
    $main-padding: 30px;
    $secondry-padding: 30px;


    .services {
        &__wrapper {
            padding: 0 $main-padding 0 $secondry-padding;
            .services__card {
                height: 120%;
    
                &-main {
                    .services__card-title {
                        margin: 15px 0 10px;    
                        font-size: 20px;
                    }
                    
                    .services__card-description {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
} 