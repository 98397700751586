@import "./../../../main/style.scss";

.arrow-btn {
    @include font($white, 16px, 700, normal, 0.1rem);
    @include display-center(flex-start, center);
    column-gap: 10px;
    text-transform: uppercase;
    cursor: pointer;
    transition: .3s;
    
    
    &--white {
        & span {
            color: white;
        }
    }

    &--gradient {
        & span {
            color: transparent !important;
            -webkit-background-clip: text !important;
            background-clip: text !important;
        }
    }


    &:hover {
        column-gap: 25px;
        transition: .3s;
    }
}