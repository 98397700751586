$main: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%);
$design-page: linear-gradient(99deg, #FE842B 5.86%, #FD2929 51.61%, #FF006B 100%);
$poster-page: linear-gradient(285deg, #68E0CF 0%, #3DCDFF 53.88%, #209CFF 104.43%);
$mobile-page: linear-gradient(99deg, #84FAB0 5.86%, #8FD3F4 100%);
$card-page: linear-gradient(281deg, #000428 -7.61%, #1A55AC 35%, #004E92 100%);

$black: #000;
$ligth-dark: #121212;
$secondary-dark: #0C0C0C;
$white: #fff;
$error-color: #FF006B;
$grey: #a7a7a7;

// margin & padding

$section-margin: 140px;
$main-padding: 120px;
$secondry-padding: 230px;
$line-margin: 30px;
$line-width: 80px;




@media screen and (max-width: 1200px) {
    $main-padding: 100px;
    $secondry-padding: 190px;
}