.social-media {
    transition: .4s;
    width: 50px;
    height: 50px;

    & svg path {
        transition: .4s;
    }

    &:hover {
        transition: .4s;
    }

    &--whats-up:hover  {
        svg path {
            fill: #25D366;
        }
    }

    &--email:hover  {
        svg {
            path:nth-child(1) {fill: #4285F4;}
            path:nth-child(2) {fill: #34A853;}
            path:nth-child(3) {fill: #FBBC04;}
            path:nth-child(4) {fill: #EA4335;}
            path:nth-child(5) {fill: #C5221F;}
        }
    }

    &--telegram:hover  {
        svg path {
            fill: #34AADF;
        }
    }
}



@media screen and (max-width: 1200px) {
    .social-media, .social-media svg{
        width: 42px;
        height: 42px;
    }
}


@media screen and (max-width: 800px) {
    .social-media, .social-media svg{
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 500px) {
    .social-media, .social-media svg{
        width: 38px;
        height: 38px;
    }
}