@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import "./variables";

@mixin font($color, $size, $weight, $line, $spacing) {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: $weight;
    font-size: $size;
    line-height: $line;
    letter-spacing: $spacing;
    color: $color;
}

@mixin display-center($justify, $align) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}


@mixin title() {
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    font-style: normal;
    color: $white;
    font-weight: 700;
    line-height: normal;
    white-space: pre-line;
}

@mixin secondary-title() {
    color: rgba(255, 255, 255, 0.20);
    font-family: 'Montserrat', sans-serif;
    font-size: 128px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 150px;
    margin-top: 30px;
}

@mixin secondary-description() {
    font-family: 'Montserrat', sans-serif;
    color: $white;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 1400px) {
        font-size: 18px;
    }

    @media screen and (max-width: 1000px) {
        font-size: 22px;
    }

    @media screen and (max-width: 1000px) {
        font-size: 20px;
    }

    @media screen and (max-width: 800px) {
        font-size: 18px;
    }
}

@mixin subTitle() {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: $white;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}


@mixin secondary-subTitle() {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: $white;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
}


