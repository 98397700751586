@import "../../../main/style.scss";

.hidden {
	display: none !important;
}

.loader-background {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	z-index: 25;
	position: fixed;
}

.loader__bg1 {
	width: 400px;
	height: 400px;
	position: absolute;
	filter: blur(300px);
	left: -10%;
	top: -10%;
	z-index: 21;
}

.loader__bg2 {
	width: 400px;
	height: 400px;
	position: absolute;
	filter: blur(300px);
	right: -10%;
	bottom: -10%;
	z-index: 21;
}

.loader {
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0%;
	top: 0%;
	z-index: 20;
	background-size: cover;
	@include display-center(center, center);
	background-color: $black;
	overflow: hidden;

	svg {
		width: 300px;
		height: 300px;
	}
	
	.item {
		width: 300px;
		height: 300px;
		overflow: visible;
		stroke: #fff;
		stroke-width: 1;
		stroke-linejoin: round;
		stroke-linecap: round;
	}

	
}

@media screen and(max-width: 1200px) {
	.loader {
		overflow-x: hidden;
		svg {
			width: 250px;
			height: 250px;
		}
		
		.item {
			width: 250px;
			height: 250px;
			overflow: visible;
			stroke: #fff;
			stroke-width: 0.5;
			stroke-linejoin: round;
			stroke-linecap: round;
		}
	
		.loader__bg1 {
			width: 200px;
			height: 200px;
			position: absolute;
			filter: blur(200px);
			left: -5%;
			top: -5%;
		}
	
		.loader__bg2 {
			width: 200px;
			height: 200px;
			position: absolute;
			filter: blur(200px);
			right: -5%;
			bottom: -5%;
		}
	}
}

@media screen and(max-width: 500px) {
	.loader__bg1 {
		width: 200px;
		height: 200px;
		position: absolute;
		filter: blur(100px);
		left: -3%;
		top: -3%;
	}

	.loader__bg2 {
		width: 200px;
		height: 200px 	;
		position: absolute;
		filter: blur(100px);
		right: -3%;
		bottom: -3%;
	}

	.loader {
		overflow: hidden;
		svg {
			width: 200px;
			height: 200px;
			z-index: 24 !important;
		}
		
		.item {
			width: 200px;
			height: 200px;
			overflow: visible;
			stroke: #fff;
			stroke-width: 0.5;
			stroke-linejoin: round;
			stroke-linecap: round;

		}
	
	
	}
}