@import "./../../../main/style.scss";

.gradient-btn {
    background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%);
    @include font(#FFF, 16px, 700, normal, 0);
    border: none;
    width: 100%;
    height: 50px;

    @include display-center(center, center);
    column-gap: 20px;
}


@media screen and (max-height: 800px) {
    .gradient-btn {
        height: 46px;
    }
}