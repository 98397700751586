@import "./../../../main/style.scss";

.localisation-field {
    @include display-center(none, center);
    column-gap: 10px;

    &__language {
        width: fit-content;
        @include font(transparent, 20px, 700, normal, 0);

        background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%);
        background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        cursor: pointer;
    }

    &__line {
        height: 25px;
        width: 2px;
        // background: linear-gradient(99deg, #2BBFFE 5.86%, #8629FD 54.99%, #FC4AF5 100%);
    }
}



@media screen and (max-width: 1200px) {
    .localisation-field {
        &__language {
            font-size: 18px;
        }
    
        &__line {
            height: 22px;
            width: 1.5px;
        }
    }
}